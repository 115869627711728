import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { KycStatus } from '@api';

import { useModalController } from '@uikit/components/Modal';
import { Checked2 } from '@uikit/icons/Checked2';
import { Close } from '@uikit/icons/Close';
import { InfoSignRounded } from '@uikit/icons/InfoSignRounded';

import { getKycStatusFlags, kycStatusLabels } from '@shared/helpers/launchpad';

import { useAuth } from '@launchpad/components/providers/AuthProvider/AuthProvider';
import KycRequiredModal from '@launchpad/components/ui/modals/KycRequiredModal/KycRequiredModal';

interface KycButtonProps extends ComponentPropsWithoutRef<'button'> {
  showLabel?: boolean | (KycStatus | undefined)[];
}

const KycButton = ({ className, showLabel, ...props }: KycButtonProps) => {
  const { isAuth, launchpadUser, isLoading } = useAuth();
  const kycRequiredModal = useModalController();
  const status = launchpadUser?.attributes.kycStatus;
  const label = status ? kycStatusLabels[status] : 'Get Verified';
  const { hasVerifiedKyc, isNeedVerification, isError } = getKycStatusFlags(status);

  if (!isAuth || isLoading) {
    return null;
  }

  const isStatusInShowLabelArray = Boolean(Array.isArray(showLabel) && showLabel.includes(status));
  const showStatusLabel = showLabel === true || isStatusInShowLabelArray;

  const commonClassName = classNames('gap-2 ui-button min-w-10 h-10 p-0 flex-shrink-0', {
    'px-3': showStatusLabel,
  });

  if (isNeedVerification || hasVerifiedKyc || isError) {
    return (
      <>
        {isNeedVerification && (
          <button
            onClick={() => kycRequiredModal.open()}
            className={classNames(
              commonClassName,
              'bg-yellow-100 text-orange-400 border border-orange-400',
              className,
            )}
            {...props}
          >
            <InfoSignRounded className="icon-xs rounded-full bg-yellow-200 flex-shrink-0" />
            {showStatusLabel && <span>{label}</span>}
          </button>
        )}
        {hasVerifiedKyc && (
          <button
            disabled
            className={classNames(
              commonClassName,
              'bg-neutral-50 text-green-700 border border-green-800',
              className,
            )}
            {...props}
          >
            <Checked2 className="icon" />
            {showStatusLabel && <span>{label}</span>}
          </button>
        )}
        {isError && (
          <button
            onClick={() => kycRequiredModal.open()}
            className={classNames(
              commonClassName,
              'bg-neutral-50 text-red-600 border border-red-600',
              className,
            )}
            {...props}
          >
            <Close className="icon" />
            {showStatusLabel && <span>{label}</span>}
          </button>
        )}

        <KycRequiredModal isDisabledSumSub={isError} {...kycRequiredModal.props} />
      </>
    );
  }

  return null;
};

export default KycButton;
