'use client';

import Script from 'next/script';

import React, { ReactNode } from 'react';

import { getProcessEnvGlobalConfig } from '@shared/config/global';

import { TonConnectUIProvider } from '@tonconnect/ui-react';

const TonProvider = ({ children }: { children?: ReactNode }) => {
  const isDev = getProcessEnvGlobalConfig('isDev');

  // Needed for Telegram Wallet to work properly.
  // Due to the VPN, telegram are not able to get the manifest file
  const host = isDev ? 'https://magic.store' : process.env.NEXT_PUBLIC_HOST;

  return (
    // NOTE: throws warning: Extra attributes from the server: ontouchstart,style
    <TonConnectUIProvider language="en" manifestUrl={`${host}/tonconnect-manifest.json`}>
      {children}
      <Script src="https://telegram.org/js/telegram-web-app.js" async defer strategy="lazyOnload" />
    </TonConnectUIProvider>
  );
};

export default TonProvider;
