'use client';
import React, { ReactNode, useEffect } from 'react';
import { toast } from 'react-toastify';

import Cookies from 'js-cookie';
import { useDisconnect } from 'wagmi';

import { storageKeys } from '@shared/constants';
import clearSavedConnectionDetails from '@shared/helpers/web3/clearSavedConnectionDetails';

import { useAuthModal } from '@launchpad/components/ui/buttons/ConnectWalletButton';

import { useAuth } from '../AuthProvider/AuthProvider';

interface ReauthProviderProps {
  children?: ReactNode;
}

const ReauthProvider = ({ children }: ReauthProviderProps) => {
  const { login } = useAuthModal();
  const { isAuth, logout } = useAuth();
  const { disconnectAsync } = useDisconnect();

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    const provider = window.ethereum;

    if (!provider) {
      return;
    }

    const handleAccountChange = async ([account]: string[]) => {
      const connectedAddress = localStorage.MSQconnectedAddress?.toLowerCase();

      if (
        connectedAddress !== account &&
        !Cookies.get(storageKeys.PREVENT_LOG_OUT_ON_ACCOUNT_CHANGE)
      ) {
        clearSavedConnectionDetails();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        provider?.removeListener?.('accountsChanged', handleAccountChange);
        toast.info('Disconnected due to account changed');
        await disconnectAsync();

        logout();

        login();
      }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    provider?.on?.('accountsChanged', handleAccountChange);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      provider.removeListener?.('accountsChanged', handleAccountChange);
    };
  }, [isAuth, disconnectAsync, logout, login]);

  return <>{children}</>;
};

export default ReauthProvider;
