import Link from 'next/link';

import React, { ComponentPropsWithoutRef, MouseEvent, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { useDisconnect } from 'wagmi';

import { useGetSIWENonceMutation, useLoginViaTonWalletMutation } from '@query';

import { WALLET_NETWORK } from '@api';

import { Dropdown, DropdownItem } from '@uikit/components/Dropdown';
import { Modal, ModalCloseButton, ModalHeading, useModalController } from '@uikit/components/Modal';
import ModalContent from '@uikit/components/Modal/ModalContent';
import Spinner from '@uikit/components/Spinner/Spinner';
import { AngleRight } from '@uikit/icons/AngleRight';

import displayErrorToast from '@shared/helpers/displayErrorToast';
import { getErrorMessage } from '@shared/helpers/error';
import truncate from '@shared/helpers/web3/truncate';

import { useAuth } from '@launchpad/components/providers/AuthProvider/AuthProvider';
import { useTonConnectUI, Wallet } from '@tonconnect/ui-react';

interface Props extends ComponentPropsWithoutRef<'button'> {
  showDisconnectPrefix?: boolean;
}

const ConnectTonWalletButtonLaunchpad = ({ showDisconnectPrefix, className, ...props }: Props) => {
  const { isAuth, user, isLoading, logout } = useAuth();
  const { disconnect } = useDisconnect();
  const [tonConnectUi] = useTonConnectUI();
  const { mutateAsync: getWalletNonce } = useGetSIWENonceMutation();
  const { mutateAsync: login, isPending: isConnectingTonWallet } = useLoginViaTonWalletMutation();
  const modal = useModalController();
  const [confirmSignUpWallet, setConfirmSignUpWallet] = useState<Wallet>();

  const tonAddress = useMemo(() => {
    return user?.wallets?.find((wallet) => wallet.attributes.network === WALLET_NETWORK.TON)
      ?.attributes?.address;
  }, [user]);

  const handleLogin = async ({
    wallet,
    userCreationAllowed = false,
  }: {
    wallet: Wallet;
    userCreationAllowed: boolean;
  }) => {
    try {
      await login({ ...wallet, userCreationAllowed });

      toast.success('TON wallet successfully connected');

      if (modal.isOpen) {
        modal.close();
      }
    } catch (e) {
      const error = e as unknown as { code?: number; message?: string };

      if (
        error?.code === 400 &&
        error?.message === 'User creation not allowed' &&
        !userCreationAllowed
      ) {
        setConfirmSignUpWallet(wallet);
        modal.open();

        return;
      }

      toast.error(`Unable to connect TON wallet. ${getErrorMessage(error)}`);
    }
  };

  const handleButtonClick = async () => {
    tonConnectUi.setConnectRequestParameters({ state: 'loading' });

    tonConnectUi.openModal();

    if (tonConnectUi.wallet) {
      await tonConnectUi.disconnect();
    }

    let nonce = '';

    try {
      nonce = await getWalletNonce();
    } catch (error) {
      toast.error(`Unable to retrieve ton proof. ${getErrorMessage(error)}`);

      tonConnectUi.closeModal();

      return;
    }

    tonConnectUi.setConnectRequestParameters({ state: 'ready', value: { tonProof: nonce } });

    const unsubscribe = tonConnectUi.onModalStateChange(async (state) => {
      try {
        unsubscribe();

        if (
          state.closeReason !== 'wallet-selected' ||
          !tonConnectUi.wallet?.connectItems?.tonProof
        ) {
          return;
        }

        await handleLogin({ wallet: tonConnectUi.wallet, userCreationAllowed: false });
      } catch (error) {
        displayErrorToast({ error });
      }
    });
  };

  const handleDisconnect = () => {
    logout();
    disconnect();
  };

  const isConnectWalletButtonDisabled = isLoading || isConnectingTonWallet;

  if (!tonAddress || !isAuth) {
    return (
      <>
        <button
          disabled={isConnectWalletButtonDisabled}
          onClick={handleButtonClick}
          data-qa="connect-wallet-button"
          className={classNames('ui-button ui-button-solid-primary min-w-32 px-4', className)}
          {...props}
        >
          {!isAuth ? 'Connect Wallet' : 'No TON Wallet'}
        </button>
        <Modal {...modal.props}>
          <ModalContent className="flex w-full max-w-[520px] text-center flex-col p-6 sm:px-12 border-0">
            <ModalCloseButton className="self-end" />

            <ModalHeading className="mb-6">Confirm Sign Up</ModalHeading>

            <p className="text-center font-semibold">
              Account with this address was not found. Would you like to create a new Magic ID
              Account?
            </p>

            <div className="flex gap-3">
              <button
                className={classNames(
                  'button button-outline-primary button-lg text-primary flex-1 gap-2 mt-8 transition',
                )}
                onClick={modal.close}
              >
                Cancel
              </button>

              <button
                disabled={!confirmSignUpWallet || isLoading}
                className={classNames(
                  'button button-primary button-lg text-neutral-50 flex-1 gap-2 mt-8 transition flex',
                )}
                onClick={() =>
                  confirmSignUpWallet
                    ? handleLogin({ wallet: confirmSignUpWallet, userCreationAllowed: true })
                    : modal.close()
                }
              >
                {isLoading && <Spinner size={'sm'} color={'light'} />}
                Sign Up
              </button>
            </div>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <Dropdown
      trigger={({ triggerProps: { onClick, ...triggerProps } }) => {
        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
          if (onClick) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            onClick(event);
          }
        };

        return (
          <button
            disabled={isLoading}
            onClick={handleClick}
            data-qa="connected-wallet-button"
            {...triggerProps}
            className={classNames(
              'ui-button ui-button-solid-primary min-w-32 gap-2 px-4',
              className,
            )}
            {...props}
          >
            {showDisconnectPrefix && 'Disconnect '}
            {truncate(tonAddress)}
            <AngleRight className={'icon-xs rotate-90'} />
          </button>
        );
      }}
    >
      <DropdownItem Component={Link} href="/portfolio" label="Dashboard" />
      <DropdownItem onClick={handleDisconnect} label="Disconnect" />
    </Dropdown>
  );
};

export default ConnectTonWalletButtonLaunchpad;
