import { ComponentPropsWithoutRef, useId } from 'react';

// separate component to prevent id collisions
// Note: Keep this file and emoji-prize.svg in uikit similar html markup
const PrizeEmoji = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 1 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${gradientIds[0]})`}>
        <path
          d="M2.2766 2.22222H4.94326C5.43393 2.22222 5.77749 2.176 5.77749 2.66666V1.77777C5.77749 1.28711 5.43393 0.888885 4.94326 0.888885H1.38771C0.49882 0.888885 -0.204736 1.83333 0.0543751 3.11111C0.0543751 3.11111 0.850375 7.27777 0.906375 7.53689C1.05438 8.22222 1.85037 8.88889 2.70237 8.88889H5.79482C6.28593 8.88889 5.77749 8.04622 5.77749 7.55555V6.66666C5.77749 7.15733 5.43393 7.55555 4.94326 7.55555H3.16549C2.67482 7.55555 2.35082 7.09244 2.2766 6.66666C2.20238 6.24089 1.5726 3.40755 1.5726 3.40755C1.4246 2.55555 1.78549 2.22222 2.2766 2.22222ZM13.7486 2.22222H11.0819C10.5913 2.22222 9.77749 2.176 9.77749 2.66666V1.77777C9.77749 1.28711 10.5913 0.888885 11.0819 0.888885H14.6375C15.5264 0.888885 16.2304 1.83333 15.9708 3.11111C15.9708 3.11111 15.245 7.29733 15.1837 7.55555C15.0264 8.22222 14.2762 8.88889 13.4059 8.88889H10.2299C9.73926 8.88889 9.77749 8.04622 9.77749 7.55555V6.66666C9.77749 7.15733 10.5913 7.55555 11.0819 7.55555H12.8597C13.3504 7.55555 13.6748 7.09244 13.7486 6.66666C13.8224 6.24089 14.4526 3.40755 14.4526 3.40755C14.6002 2.55555 14.2397 2.22222 13.7486 2.22222ZM9.25838 9.77777C9.25838 6.68533 8.05437 9.77777 8.05437 9.77777C8.05437 9.77777 6.72104 6.68533 6.72104 9.77777C6.72104 12.8702 5.25838 14.2222 5.25838 14.2222H10.7215C10.721 14.2222 9.25838 12.8702 9.25838 9.77777Z"
          fill="#FFAC33"
        />
        <path
          d="M12.9438 2.92311C12.9438 5.93422 9.9349 11.0084 8.0549 11.0084C6.17446 11.0084 3.16602 5.93422 3.16602 2.92311C3.16602 0.614219 3.61046 0.114664 4.49935 0.114664C5.11002 0.114664 7.17713 0.102664 8.0549 0.102664L11.166 0.102219C12.4625 0.101775 12.9438 0.406219 12.9438 2.92311Z"
          fill="#FFCC4D"
        />
        <path
          d="M12 14.6667C12 15.1573 12.1009 15.5556 11.6102 15.5556H4.49911C4.008 15.5556 4 15.1573 4 14.6667V14.2222C4 13.7316 4.51733 13.3333 4.98044 13.3333H11.1658C11.6289 13.3333 12 13.7316 12 14.2222V14.6667Z"
          fill="#C1694F"
        />
        <path
          d="M12.8891 15.3889C12.8891 15.7267 12.9624 16 12.3327 16H3.77711C3.20288 16 3.11133 15.7267 3.11133 15.3889V15.2778C3.11133 14.94 3.43933 14.6667 3.77711 14.6667H12.3327C12.67 14.6667 12.8891 14.94 12.8891 15.2778V15.3889Z"
          fill="#C1694F"
        />
      </g>
      <defs>
        <clipPath id={gradientIds[0]}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PrizeEmoji;
