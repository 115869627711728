'use client';

import Script from 'next/script';

import { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';

const MainLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Header />
      <div className="pt-header-sm md:pt-header flex-grow">{children}</div>
      <Footer />
      <Script src="https://accounts.google.com/gsi/client" async defer strategy="lazyOnload" />
    </>
  );
};

export default MainLayout;
