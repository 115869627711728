'use client';

import React, { ReactNode } from 'react';

import BanxaModal from '@shared/common/components/modals/BanxaModal';
import ConnectAndSignModal from '@shared/common/components/modals/ConnectAndSignModal';

const GlobalModalsLaunchpad = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {children}
      <ConnectAndSignModal />
      <BanxaModal />
    </>
  );
};

export default GlobalModalsLaunchpad;
