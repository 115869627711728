import Link, { LinkProps } from 'next/link';
import { usePathname } from 'next/navigation';

import React, { ComponentPropsWithoutRef, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { KYC_STATUS } from '@api';

import { Dropdown, DropdownItem } from '@uikit/components/Dropdown';
import { DropdownMenuProps } from '@uikit/components/Dropdown/DropdownMenu';
import ExternalLink from '@uikit/components/ExternalLink/ExternalLink';
import { Modal, ModalCloseButton, ModalContent, useModalController } from '@uikit/components/Modal';
import Money from '@uikit/icons/Money';

import { LAUNCHPAD_INTERNAL_ROUTES } from '@shared/constants';
import PrizeEmoji from '@shared/constants/icons/emoji/PrizeEmoji';
import RocketEmoji from '@shared/constants/icons/emoji/RocketEmoji';
import LaunchpadLogoLong from '@shared/constants/icons/LaunchpadLogoLong';
import { createSafeContext, useSafeContext } from '@shared/helpers/context';
import useTelegramMiniAppSdk from '@shared/hooks/useTelegramMiniAppSdk';

import ConnectTonWalletButtonLaunchpad from '@launchpad/components/ui/buttons/ConnectTonWalletButtonLaunchpad';
import ConnectWalletButton from '@launchpad/components/ui/buttons/ConnectWalletButton';
import KycButton from '@launchpad/components/ui/buttons/KycButton';

import { useAuth } from '../providers/AuthProvider/AuthProvider';
import Packman from './assets/pacman.gif';

interface HeaderProps {
  className?: string;
}

interface ContextValue {
  openMenu: () => void;
  closeMenu: () => void;
}

const Context = createSafeContext<ContextValue>();

const useHeaderProvider = () => useSafeContext(Context);

const HeaderLogo = (props: Omit<LinkProps & ComponentPropsWithoutRef<'a'>, 'href'>) => {
  return (
    <Link href="/" {...props}>
      <LaunchpadLogoLong className="h-7" />
    </Link>
  );
};

const nav: {
  id: string;
  label: ReactNode;
  path: string;
  isProtected?: boolean;
  isExternalLink?: boolean;
}[] = [
  {
    id: 'portfolio',
    label: 'Dashboard',
    path: LAUNCHPAD_INTERNAL_ROUTES.dashboard,
    isProtected: true,
  },
  {
    id: 'sales',
    label: (
      <div className="flex gap-1 items-center" data-qa="sales-button">
        IDO Sales
        <Money className="icon-[14px]" />
      </div>
    ),
    path: LAUNCHPAD_INTERNAL_ROUTES.projects,
  },
  {
    id: 'early-access-sales',
    label: (
      <div className="flex gap-1 items-center" data-qa="early-access-sales-button">
        Early Access Sales (EATS)
        <ImageProxy src={Packman} width={20} height={20} alt="Early Access Sales" />
      </div>
    ),
    path: LAUNCHPAD_INTERNAL_ROUTES.earlySales,
  },
  {
    id: 'campaigns',
    label: (
      <div className="flex gap-1 items-center" data-qa="road-to-ido-button">
        Road-to-IDO
        <PrizeEmoji className="icon-[14px]" />
      </div>
    ),
    path: LAUNCHPAD_INTERNAL_ROUTES.campaigns,
  },
];

const HeaderNav = ({ className }: { className?: string }) => {
  const pathname = usePathname();
  const { closeMenu } = useHeaderProvider();
  const { isAuth } = useAuth();

  return (
    <div className={classNames('flex gap-4 flex-wrap', className)}>
      {nav.map(({ id, label, path, isProtected, isExternalLink }) => {
        if (isProtected && !isAuth) {
          return null;
        }

        const isActive = pathname.startsWith(path);

        if (isExternalLink) {
          return (
            <ExternalLink
              key={id}
              onClick={() => closeMenu()}
              className="max-md:p-3 max-md:border-b max-md:border-primary-100 font-medium max-lg:text-sm text-neutral-500"
              href={path}
            >
              {label}
            </ExternalLink>
          );
        }

        return (
          <Link
            key={id}
            onClick={() => closeMenu()}
            className={classNames(
              'max-md:p-3 max-md:border-b max-md:border-primary-100 font-medium max-lg:text-sm',
              {
                'text-secondary-700': isActive,
                'text-neutral-500': !isActive,
              },
            )}
            href={path}
          >
            {label}
          </Link>
        );
      })}
    </div>
  );
};

const LinkToApplyKol = ({ className, ...props }: ComponentPropsWithoutRef<'a'>) => (
  <ExternalLink
    href="https://forms.gle/DhPSsbhvMqL3vHPq5"
    className={classNames(
      'ui-button ui-button-solid-secondary lg:min-w-32 leading-4 px-3',
      className,
    )}
    {...props}
  >
    Launch with Us <RocketEmoji className="icon-[14px]" />
  </ExternalLink>
);

const LinkToStore = ({ className, ...props }: ComponentPropsWithoutRef<'a'>) => (
  <ExternalLink
    href="https://magic.store"
    className={classNames(
      'ui-button ui-button-solid-secondary lg:min-w-32 leading-4 px-3',
      className,
    )}
    {...props}
  >
    Go to Magic Store
  </ExternalLink>
);

const DropdownNav = ({ className, ...props }: Omit<DropdownMenuProps, 'trigger'>) => {
  const pathname = usePathname();
  const { isAuth } = useAuth();

  return (
    <Dropdown
      {...props}
      trigger={({ triggerProps: { onClick, ...triggerProps } }) => {
        return (
          <button
            {...triggerProps}
            data-qa="drop-down-menu"
            className={classNames(
              'w-10 aspect-square p-3 bg-secondary rounded-lg flex flex-col gap-1',
              className,
            )}
          >
            {Array.from({ length: 3 }, (_, i) => {
              return <span key={i} className="h-0.5 w-full bg-neutral-50" />;
            })}
          </button>
        );
      }}
    >
      {nav.map(({ id, label, path, isProtected, isExternalLink }) => {
        if (isProtected && !isAuth) {
          return null;
        }

        const isActive = pathname.startsWith(path);

        if (isExternalLink) {
          return (
            <DropdownItem
              Component={ExternalLink}
              key={id}
              href={LAUNCHPAD_INTERNAL_ROUTES.dashboard}
              label={id}
              className="font-medium max-lg:text-sm text-neutral-500"
            >
              {label}
            </DropdownItem>
          );
        }

        return (
          <DropdownItem
            Component={Link}
            key={id}
            href={path}
            label={id}
            className={classNames('font-medium max-lg:text-sm', {
              'text-secondary-700': isActive,
              'text-neutral-500': !isActive,
            })}
          >
            {label}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};

const Header = ({ className }: HeaderProps) => {
  const { close: closeMenu, open: openMenu, props: menuProps } = useModalController();

  const value = useMemo(() => {
    return {
      openMenu,
      closeMenu,
    };
  }, [closeMenu, openMenu]);

  const { isAppOpenedInTelegram } = useTelegramMiniAppSdk();

  return (
    <Context.Provider value={value}>
      <Modal {...menuProps}>
        <ModalContent
          overlayClassName="lg:hidden p-0"
          className="flex flex-col items-start justify-center mt-0 w-full rounded-t-none rounded-b-2xl px-4 py-6"
        >
          <HeaderLogo onClick={closeMenu} className="mb-6 self-center" />
          <HeaderNav className="flex-col w-full mb-6 gap-0" />

          <div className="w-full flex flex-col items-center justify-center gap-2">
            <LinkToApplyKol className="w-full" />
            <LinkToStore className="w-full" />
            {isAppOpenedInTelegram ? (
              <ConnectTonWalletButtonLaunchpad
                className="w-full"
                showDisconnectPrefix
                suppressHydrationWarning
              />
            ) : (
              <ConnectWalletButton
                className="w-full"
                showDisconnectPrefix
                suppressHydrationWarning
              />
            )}

            <KycButton className="w-full" showLabel />
          </div>

          <ModalCloseButton className="!self-center !mb-0 mt-6 !border-neutral-100 border flex justify-center items-center w-8 !p-0" />
        </ModalContent>
      </Modal>

      <header
        className={classNames(
          'fixed top-0 left-0 w-full border-b border-[#414762]/10 bg-neutral-50 z-10',
          'h-header-sm lg:h-header flex items-center justify-between px-4 sm:px-10',
          className,
        )}
      >
        <button onClick={openMenu} className="flex flex-col gap-1 lg:hidden w-6">
          {Array.from({ length: 3 }, (_, i) => {
            return <span key={i} className="h-0.5 w-full bg-neutral-700" />;
          })}
        </button>

        <div className="h-10 flex justify-start items-center max-lg:hidden">
          <HeaderLogo className="mr-4 lg:mr-8 flex items-center h-full" />
          <HeaderNav className="max-2xl:hidden" />
        </div>

        <div className="h-10 flex gap-2">
          <DropdownNav className="max-lg:hidden 2xl:hidden" />
          <LinkToApplyKol className="max-lg:hidden" />
          <LinkToStore className="max-lg:hidden" />

          {isAppOpenedInTelegram ? (
            <ConnectTonWalletButtonLaunchpad suppressHydrationWarning />
          ) : (
            <ConnectWalletButton suppressHydrationWarning />
          )}
          <KycButton className="max-lg:hidden" showLabel={[KYC_STATUS.INIT, undefined]} />
        </div>
      </header>
    </Context.Provider>
  );
};

export default Header;
