import { ChangeEvent, useState } from 'react';

import { Address } from 'viem';

import {
  Checkbox,
  ExternalLink,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeading,
} from '@uikit';

import { BrandColorBanxaFull } from '@uikit/components/icons/BrandColorBanxaFull';

import { useBanxaModal } from '@shared/common/providers/GlobalModalsProvider';
import useGlobalState from '@shared/common/providers/GlobalStateProvider';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

const SANDBOX_BANXA_URL = 'https://magic.banxa-sandbox.com/';
const PROD_BANXA_URL = 'https://magic.banxa.com/';

const getBanxaUrl = ({
  walletAddress,
  isSandbox,
}: {
  walletAddress?: Address;
  isSandbox: boolean;
}) => {
  const searchParams = new URLSearchParams({
    coinType: 'SQR',
    blockchain: 'BSC',
    backgroundColor: 'ffffff',
    primaryColor: 'e014c2',
    secondaryColor: '4287f5',
    textColor: '000000',
    theme: 'light',
    ...(walletAddress && { walletAddress }),
  });
  const banxaUrl = isSandbox ? SANDBOX_BANXA_URL : PROD_BANXA_URL;

  return `${banxaUrl}?${searchParams}`;
};

const BanxaModal = () => {
  const modal = useBanxaModal();
  const featureToggles = useFeatureToggles();
  const { value } = useGlobalState('banxa');
  const { walletAddress, productName } = value || {};

  const banxaUrl = getBanxaUrl({
    walletAddress,
    isSandbox: !featureToggles?.banxaSqrTokenSourceProdMode,
  });

  const [isConfirm, setIsConfirm] = useState(true);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => setIsConfirm(event.target.checked);

  return (
    <Modal {...modal.props}>
      <ModalContent className="flex flex-col items-center p-6 sm:px-12  w-full max-w-[472px] text-center">
        <ModalCloseButton className="!mb-4" />
        <ModalHeading className="mb-4">Disclaimer</ModalHeading>

        <p className="font-light text-sm text-neutral-500 mb-4">
          You are now leaving {productName} for Banxa (
          <ExternalLink className="text-secondary" href="https://banxa.com">
            https://banxa.com
          </ExternalLink>
          ).
        </p>
        <p className="font-light text-sm text-neutral-500 mb-4">
          Services related to card payments are provided by Banxa, a separate third-party platform.
          By proceeding and procuring services from Banxa, you acknowledge that you have read and
          agreed to Banxa's{' '}
          <ExternalLink
            className="text-secondary"
            href="https://banxa.com/wp-content/uploads/2024/03/Privacy-and-Cookies-Policy-3-March-2024-Banxa.pdf"
          >
            Terms of Use
          </ExternalLink>{' '}
          and{' '}
          <ExternalLink
            className="text-secondary"
            href="https://banxa.com/wp-content/uploads/2024/03/Privacy-and-Cookies-Policy-3-March-2024-Banxa.pdf"
          >
            Privacy and Cookies Policy
          </ExternalLink>
          . For any questions related to Banxa's services, please contact Banxa at
          support.banxa.com.
        </p>
        <div className="flex flex-row-reverse items-center justify-start mb-8">
          <div className="flex justify-start gap-6">
            <Checkbox onChangeCapture={handleToggle} defaultChecked={isConfirm}>
              <p className="font-light text-sm text-neutral-500 text-left">
                I have read and agree to the disclaimer.
              </p>
            </Checkbox>
          </div>
        </div>
        {isConfirm && (
          <ExternalLink
            className="button button-lg w-full bg-secondary text-neutral-50"
            href={banxaUrl}
          >
            Confirm
          </ExternalLink>
        )}
        {!isConfirm && (
          <button disabled className="button button-lg w-full">
            Confirm
          </button>
        )}
        <div className="font-light text-sm text-neutral-500 flex justify-center items-center gap-1 mt-8">
          Powered by <BrandColorBanxaFull />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default BanxaModal;
