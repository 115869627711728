import { ComponentPropsWithoutRef, useId } from 'react';

// separate component to prevent id collisions
// Note: Keep this file and launchpad-logo-long.svg in uikit similar html markup
const LaunchpadLogoLong = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 11 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 223 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M215.751 21.2751C214.675 21.2751 213.711 21.01 212.86 20.4799C212.008 19.9498 211.333 19.2349 210.835 18.3352C210.353 17.4195 210.112 16.3833 210.112 15.2266C210.112 14.07 210.353 13.0418 210.835 12.1422C211.333 11.2265 212.008 10.5035 212.86 9.97339C213.711 9.44324 214.675 9.17817 215.751 9.17817C216.587 9.17817 217.342 9.35488 218.016 9.70831C218.691 10.0457 219.229 10.5196 219.631 11.1301C220.049 11.7405 220.266 12.4313 220.282 13.2025V17.2026C220.266 17.9738 220.057 18.6726 219.655 19.2991C219.253 19.9096 218.707 20.3915 218.016 20.745C217.342 21.0984 216.587 21.2751 215.751 21.2751ZM216.257 18.4316C216.868 18.4316 217.398 18.2951 217.848 18.022C218.298 17.7488 218.643 17.3713 218.884 16.8894C219.141 16.4074 219.269 15.8532 219.269 15.2266C219.269 14.584 219.141 14.0298 218.884 13.5639C218.643 13.082 218.298 12.7044 217.848 12.4313C217.398 12.1582 216.876 12.0217 216.281 12.0217C215.687 12.0217 215.165 12.1582 214.715 12.4313C214.265 12.7044 213.912 13.082 213.655 13.5639C213.398 14.0459 213.269 14.6001 213.269 15.2266C213.269 15.8532 213.39 16.4074 213.631 16.8894C213.888 17.3713 214.241 17.7488 214.691 18.022C215.157 18.2951 215.679 18.4316 216.257 18.4316ZM222.161 21.0341H219.101V17.9015L219.607 15.0821L219.077 12.2626V3.63574H222.161V21.0341Z"
        fill="#09090B"
      />
      <path
        d="M201.824 21.2747C200.764 21.2747 199.808 21.0096 198.957 20.4795C198.121 19.9493 197.455 19.2344 196.957 18.3348C196.475 17.4191 196.234 16.3909 196.234 15.2503C196.234 14.0936 196.475 13.0574 196.957 12.1417C197.455 11.226 198.121 10.5031 198.957 9.97295C199.808 9.44281 200.764 9.17773 201.824 9.17773C202.66 9.17773 203.407 9.35445 204.065 9.70788C204.74 10.0452 205.278 10.5192 205.68 11.1296C206.082 11.7401 206.29 12.4309 206.307 13.202V17.2504C206.29 18.0215 206.082 18.7123 205.68 19.3228C205.294 19.9333 204.764 20.4152 204.09 20.7686C203.415 21.106 202.66 21.2747 201.824 21.2747ZM202.379 18.4312C203.278 18.4312 204.001 18.134 204.547 17.5396C205.11 16.9291 205.391 16.158 205.391 15.2262C205.391 14.5836 205.262 14.0294 205.005 13.5635C204.764 13.0815 204.419 12.704 203.969 12.4309C203.519 12.1578 202.997 12.0212 202.403 12.0212C201.808 12.0212 201.286 12.1578 200.836 12.4309C200.387 12.704 200.033 13.0815 199.776 13.5635C199.519 14.0454 199.391 14.5997 199.391 15.2262C199.391 15.8527 199.519 16.407 199.776 16.8889C200.033 17.3709 200.387 17.7484 200.836 18.0215C201.286 18.2946 201.8 18.4312 202.379 18.4312ZM205.222 21.0337V17.901L205.728 15.0816L205.222 12.2622V9.41871H208.283V21.0337H205.222Z"
        fill="#09090B"
      />
      <path
        d="M189.512 21.2747C188.661 21.2747 187.898 21.106 187.223 20.7686C186.548 20.4152 186.01 19.9333 185.608 19.3228C185.207 18.7123 184.99 18.0215 184.958 17.2504V13.2502C184.99 12.4791 185.207 11.7883 185.608 11.1778C186.01 10.5513 186.548 10.0613 187.223 9.70788C187.914 9.35445 188.677 9.17773 189.512 9.17773C190.572 9.17773 191.528 9.44281 192.38 9.97295C193.231 10.5031 193.898 11.226 194.38 12.1417C194.878 13.0574 195.127 14.0936 195.127 15.2503C195.127 16.3909 194.878 17.4191 194.38 18.3348C193.898 19.2344 193.231 19.9493 192.38 20.4795C191.528 21.0096 190.572 21.2747 189.512 21.2747ZM188.982 18.4312C189.56 18.4312 190.074 18.2946 190.524 18.0215C190.99 17.7484 191.352 17.3709 191.609 16.8889C191.866 16.407 191.994 15.8527 191.994 15.2262C191.994 14.5836 191.866 14.0294 191.609 13.5635C191.352 13.0815 190.998 12.704 190.548 12.4309C190.098 12.1578 189.576 12.0212 188.982 12.0212C188.388 12.0212 187.857 12.1578 187.392 12.4309C186.942 12.6879 186.588 13.0574 186.331 13.5394C186.09 14.0213 185.97 14.5836 185.97 15.2262C185.97 15.8527 186.09 16.407 186.331 16.8889C186.588 17.3709 186.942 17.7484 187.392 18.0215C187.857 18.2946 188.388 18.4312 188.982 18.4312ZM183.078 25.9014V9.41871H186.163V12.4791L185.632 15.2985L186.138 18.1179V25.9014H183.078Z"
        fill="#09090B"
      />
      <path
        d="M177.691 21.0341V14.3591C177.691 13.6523 177.466 13.082 177.016 12.6482C176.582 12.1984 176.02 11.9735 175.329 11.9735C174.847 11.9735 174.421 12.0779 174.052 12.2867C173.698 12.4795 173.417 12.7607 173.208 13.1302C173.016 13.4836 172.919 13.8932 172.919 14.3591L171.714 13.7326C171.714 12.833 171.907 12.0458 172.293 11.371C172.678 10.6802 173.216 10.1421 173.907 9.75651C174.598 9.37095 175.377 9.17817 176.245 9.17817C177.128 9.17817 177.907 9.37095 178.582 9.75651C179.273 10.126 179.811 10.6481 180.197 11.3228C180.582 11.9976 180.775 12.7848 180.775 13.6844V21.0341H177.691ZM169.835 21.0341V3.63574H172.919V21.0341H169.835Z"
        fill="#09090B"
      />
      <path
        d="M163.709 21.2753C162.536 21.2753 161.476 21.0103 160.528 20.4801C159.58 19.95 158.833 19.2271 158.287 18.3113C157.757 17.3956 157.492 16.3675 157.492 15.2269C157.492 14.0702 157.765 13.034 158.311 12.1183C158.857 11.2026 159.596 10.4797 160.528 9.94952C161.476 9.41937 162.544 9.1543 163.733 9.1543C164.633 9.1543 165.468 9.32298 166.239 9.66034C167.01 9.99771 167.677 10.4797 168.239 11.1062L166.263 13.1063C165.942 12.7529 165.564 12.4878 165.131 12.3111C164.713 12.1344 164.247 12.046 163.733 12.046C163.123 12.046 162.584 12.1825 162.119 12.4557C161.653 12.7127 161.283 13.0822 161.01 13.5641C160.753 14.03 160.624 14.5843 160.624 15.2269C160.624 15.8373 160.753 16.3835 161.01 16.8655C161.283 17.3474 161.653 17.725 162.119 17.9981C162.584 18.2712 163.123 18.4077 163.733 18.4077C164.263 18.4077 164.737 18.3194 165.155 18.1427C165.589 17.9499 165.966 17.6687 166.287 17.2993L168.263 19.2993C167.701 19.9419 167.026 20.4319 166.239 20.7693C165.468 21.1067 164.625 21.2753 163.709 21.2753Z"
        fill="#09090B"
      />
      <path
        d="M152.851 21.0337V14.3587C152.851 13.6518 152.626 13.0815 152.177 12.6478C151.743 12.198 151.181 11.973 150.49 11.973C150.008 11.973 149.582 12.0775 149.213 12.2863C148.859 12.4791 148.578 12.7602 148.369 13.1297C148.176 13.4832 148.08 13.8928 148.08 14.3587L146.875 13.7322C146.875 12.8325 147.068 12.0453 147.453 11.3706C147.855 10.6798 148.401 10.1416 149.092 9.75607C149.799 9.37051 150.586 9.17773 151.454 9.17773C152.321 9.17773 153.092 9.39461 153.767 9.82837C154.442 10.2461 154.972 10.8003 155.357 11.4911C155.743 12.1819 155.936 12.9128 155.936 13.684V21.0337H152.851ZM144.996 21.0337V9.41871H148.08V21.0337H144.996Z"
        fill="#09090B"
      />
      <path
        d="M137.492 21.2749C136.48 21.2749 135.573 21.058 134.769 20.6243C133.982 20.1905 133.364 19.5881 132.914 18.817C132.48 18.0459 132.263 17.1623 132.263 16.1662V9.41895H135.348V16.0699C135.348 16.5679 135.428 16.9936 135.589 17.347C135.765 17.7005 136.014 17.9736 136.336 18.1663C136.657 18.3431 137.043 18.4314 137.492 18.4314C138.167 18.4314 138.689 18.2306 139.059 17.829C139.444 17.4113 139.637 16.8249 139.637 16.0699V9.41895H142.697V16.1662C142.697 17.1783 142.473 18.0699 142.023 18.8411C141.589 19.6122 140.978 20.2146 140.191 20.6484C139.42 21.0661 138.521 21.2749 137.492 21.2749Z"
        fill="#09090B"
      />
      <path
        d="M123.541 21.2747C122.481 21.2747 121.525 21.0096 120.674 20.4795C119.838 19.9493 119.172 19.2344 118.674 18.3348C118.192 17.4191 117.951 16.3909 117.951 15.2503C117.951 14.0936 118.192 13.0574 118.674 12.1417C119.172 11.226 119.838 10.5031 120.674 9.97295C121.525 9.44281 122.481 9.17773 123.541 9.17773C124.377 9.17773 125.124 9.35445 125.782 9.70788C126.457 10.0452 126.995 10.5192 127.397 11.1296C127.799 11.7401 128.007 12.4309 128.024 13.202V17.2504C128.007 18.0215 127.799 18.7123 127.397 19.3228C127.011 19.9333 126.481 20.4152 125.807 20.7686C125.132 21.106 124.377 21.2747 123.541 21.2747ZM124.096 18.4312C124.995 18.4312 125.718 18.134 126.264 17.5396C126.827 16.9291 127.108 16.158 127.108 15.2262C127.108 14.5836 126.979 14.0294 126.722 13.5635C126.481 13.0815 126.136 12.704 125.686 12.4309C125.236 12.1578 124.714 12.0212 124.12 12.0212C123.525 12.0212 123.003 12.1578 122.553 12.4309C122.104 12.704 121.75 13.0815 121.493 13.5635C121.236 14.0454 121.108 14.5997 121.108 15.2262C121.108 15.8527 121.236 16.407 121.493 16.8889C121.75 17.3709 122.104 17.7484 122.553 18.0215C123.003 18.2946 123.517 18.4312 124.096 18.4312ZM126.939 21.0337V17.901L127.445 15.0816L126.939 12.2622V9.41871H130V21.0337H126.939Z"
        fill="#09090B"
      />
      <path
        d="M106.191 21.0341V4.11768H109.396V21.0341H106.191ZM108.529 21.0341V18.1906H117.18V21.0341H108.529Z"
        fill="#09090B"
      />
      <path
        d="M95.2391 21.2753C94.0664 21.2753 93.0061 21.0103 92.0582 20.4801C91.1104 19.95 90.3634 19.2271 89.8172 18.3113C89.287 17.3956 89.0219 16.3675 89.0219 15.2269C89.0219 14.0702 89.295 13.034 89.8413 12.1183C90.3875 11.2026 91.1265 10.4797 92.0582 9.94952C93.0061 9.41937 94.0744 9.1543 95.2632 9.1543C96.1628 9.1543 96.9982 9.32298 97.7693 9.66034C98.5405 9.99771 99.2072 10.4797 99.7694 11.1062L97.7934 13.1063C97.4721 12.7529 97.0946 12.4878 96.6609 12.3111C96.2432 12.1344 95.7773 12.046 95.2632 12.046C94.6527 12.046 94.1145 12.1825 93.6487 12.4557C93.1828 12.7127 92.8133 13.0822 92.5402 13.5641C92.2831 14.03 92.1546 14.5843 92.1546 15.2269C92.1546 15.8373 92.2831 16.3835 92.5402 16.8655C92.8133 17.3474 93.1828 17.725 93.6487 17.9981C94.1145 18.2712 94.6527 18.4077 95.2632 18.4077C95.7933 18.4077 96.2673 18.3194 96.685 18.1427C97.1187 17.9499 97.4962 17.6687 97.8175 17.2993L99.7935 19.2993C99.2313 19.9419 98.5565 20.4319 97.7693 20.7693C96.9982 21.1067 96.1548 21.2753 95.2391 21.2753Z"
        fill="#09090B"
      />
      <path
        d="M84.1273 21.0339V9.41886H87.2118V21.0339H84.1273ZM85.6695 7.49106C85.1555 7.49106 84.7297 7.32238 84.3924 6.98502C84.0711 6.64765 83.9104 6.22996 83.9104 5.73195C83.9104 5.23393 84.0711 4.81624 84.3924 4.47888C84.7297 4.12545 85.1555 3.94873 85.6695 3.94873C86.1836 3.94873 86.6013 4.12545 86.9226 4.47888C87.2439 4.81624 87.4046 5.23393 87.4046 5.73195C87.4046 6.22996 87.2439 6.64765 86.9226 6.98502C86.6013 7.32238 86.1836 7.49106 85.6695 7.49106Z"
        fill="#09090B"
      />
      <path
        d="M75.257 26.1665C74.02 26.1665 72.9276 25.9416 71.9797 25.4917C71.048 25.058 70.3009 24.4395 69.7387 23.6362L71.6906 21.6843C72.1404 22.2305 72.6464 22.6402 73.2087 22.9133C73.771 23.2025 74.4537 23.3471 75.257 23.3471C76.237 23.3471 77.0161 23.09 77.5944 22.5759C78.1728 22.0779 78.462 21.3871 78.462 20.5036V17.5878L78.968 15.0334L78.4861 12.4791V9.41871H81.5464V20.4554C81.5464 21.612 81.2733 22.6161 80.7271 23.4676C80.197 24.319 79.458 24.9777 78.5102 25.4436C77.5784 25.9255 76.494 26.1665 75.257 26.1665ZM75.1365 20.7445C74.0923 20.7445 73.1525 20.4955 72.3171 19.9975C71.4817 19.4834 70.8231 18.7846 70.3411 17.901C69.8752 17.0175 69.6423 16.0295 69.6423 14.937C69.6423 13.8446 69.8752 12.8647 70.3411 11.9971C70.8231 11.1296 71.4817 10.4469 72.3171 9.94886C73.1525 9.43478 74.0923 9.17773 75.1365 9.17773C76.004 9.17773 76.7671 9.34642 77.4258 9.68378C78.1005 10.0211 78.6306 10.4951 79.0162 11.1055C79.4018 11.6999 79.6106 12.3988 79.6427 13.202V16.7203C79.6106 17.5074 79.3937 18.2063 78.9921 18.8167C78.6065 19.4111 78.0764 19.8851 77.4017 20.2385C76.743 20.5759 75.9879 20.7445 75.1365 20.7445ZM75.7148 17.9251C76.3092 17.9251 76.8233 17.7966 77.2571 17.5396C77.7069 17.2825 78.0523 16.9371 78.2933 16.5034C78.5343 16.0536 78.6547 15.5395 78.6547 14.9611C78.6547 14.3667 78.5343 13.8527 78.2933 13.4189C78.0523 12.9691 77.7069 12.6156 77.2571 12.3586C76.8233 12.1016 76.3173 11.973 75.7389 11.973C75.1445 11.973 74.6224 12.1016 74.1726 12.3586C73.7388 12.6156 73.3935 12.9691 73.1364 13.4189C72.8954 13.8527 72.7749 14.3667 72.7749 14.9611C72.7749 15.5234 72.8954 16.0295 73.1364 16.4793C73.3935 16.9291 73.7388 17.2825 74.1726 17.5396C74.6224 17.7966 75.1365 17.9251 75.7148 17.9251Z"
        fill="#09090B"
      />
      <path
        d="M61.3783 21.2747C60.318 21.2747 59.3622 21.0096 58.5107 20.4795C57.6754 19.9493 57.0087 19.2344 56.5106 18.3348C56.0287 17.4191 55.7877 16.3909 55.7877 15.2503C55.7877 14.0936 56.0287 13.0574 56.5106 12.1417C57.0087 11.226 57.6754 10.5031 58.5107 9.97295C59.3622 9.44281 60.318 9.17773 61.3783 9.17773C62.2137 9.17773 62.9607 9.35445 63.6194 9.70788C64.2941 10.0452 64.8323 10.5192 65.2339 11.1296C65.6356 11.7401 65.8444 12.4309 65.8605 13.202V17.2504C65.8444 18.0215 65.6356 18.7123 65.2339 19.3228C64.8484 19.9333 64.3182 20.4152 63.6435 20.7686C62.9688 21.106 62.2137 21.2747 61.3783 21.2747ZM61.9326 18.4312C62.8322 18.4312 63.5551 18.134 64.1014 17.5396C64.6636 16.9291 64.9448 16.158 64.9448 15.2262C64.9448 14.5836 64.8162 14.0294 64.5592 13.5635C64.3182 13.0815 63.9728 12.704 63.523 12.4309C63.0732 12.1578 62.5511 12.0212 61.9567 12.0212C61.3623 12.0212 60.8402 12.1578 60.3903 12.4309C59.9405 12.704 59.5871 13.0815 59.3301 13.5635C59.073 14.0454 58.9445 14.5997 58.9445 15.2262C58.9445 15.8527 59.073 16.407 59.3301 16.8889C59.5871 17.3709 59.9405 17.7484 60.3903 18.0215C60.8402 18.2946 61.3542 18.4312 61.9326 18.4312ZM64.7761 21.0337V17.901L65.2821 15.0816L64.7761 12.2622V9.41871H67.8365V21.0337H64.7761Z"
        fill="#09090B"
      />
      <path
        d="M36.639 21.0341V4.11768H38.9042L45.7961 15.2025H44.4707L51.3626 4.11768H53.6037V21.0341H50.4228V9.61191L51.0734 9.78059L46.2057 17.5882H44.037L39.1934 9.78059L39.844 9.61191V21.0341H36.639Z"
        fill="#09090B"
      />
      <path
        d="M12.3755 0.883779C13.5539 -0.294593 15.4645 -0.294593 16.6429 0.883779L19.9048 4.14559C15.2065 5.42771 10.7434 7.80311 6.92032 11.27C6.60555 11.5554 6.11371 11.7502 5.48799 11.7772C3.80822 11.8495 2.13944 12.3132 0.631256 13.1706C0.766588 12.6894 1.02355 12.2353 1.40213 11.8567L12.3755 0.883779Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M27.6162 11.8567L24.3542 8.59487C23.0718 13.2937 20.6958 17.7573 17.2279 21.5807C16.9425 21.8953 16.7476 22.3869 16.7205 23.0124C16.6478 24.6921 16.1835 26.3608 15.3256 27.8688C15.808 27.7337 16.2634 27.4764 16.6429 27.097L27.6162 16.124C28.7946 14.9456 28.7946 13.0351 27.6162 11.8567Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.34903 16.056C0.883234 15.7187 0.87352 15.0274 1.36925 14.7359C2.66375 13.9746 4.10694 13.5626 5.56237 13.5C6.48407 13.4603 7.39547 13.1671 8.07886 12.5474C12.0637 8.93388 16.7813 6.57507 21.7227 5.47098C22.5136 5.29426 23.206 5.98659 23.0292 6.7775C21.9249 11.7194 19.5655 16.4374 15.9509 20.4225C15.3313 21.1056 15.038 22.0166 14.9981 22.938C14.9351 24.3925 14.5231 25.8348 13.7622 27.1285C13.4707 27.6242 12.7794 27.6145 12.4421 27.1487L11.3945 25.702C10.9933 25.148 10.4097 24.7617 9.77287 24.5118C8.50213 24.0131 7.31098 23.2507 6.2836 22.2245L6.2753 22.2162C5.24893 21.1887 4.48639 19.9974 3.98771 18.7265C3.73781 18.0896 3.35153 17.506 2.7974 17.1047L1.34903 16.056ZM15.7991 10.317C16.4831 9.96332 17.3173 10.0929 17.8618 10.6373C18.4065 11.182 18.5359 12.0166 18.1818 12.7006L17.6318 13.7632C16.9633 15.0545 15.232 15.323 14.2038 14.2948C13.1754 13.2664 13.4443 11.5347 14.7363 10.8666L15.7991 10.317Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="21.5328"
          y1="3.97104e-08"
          x2="14.3359"
          y2="27.8093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="21.5328"
          y1="3.97104e-08"
          x2="14.3359"
          y2="27.8093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="21.5328"
          y1="3.97104e-08"
          x2="14.3359"
          y2="27.8093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LaunchpadLogoLong;
