import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

import Cookies from 'js-cookie';

import { useAuthMutation } from '@query';

import { RequestSignResult } from '@api';

import { namedWeb3Errors, storageKeys } from '@shared/constants';
import { normalizeError } from '@shared/helpers/displayErrorToast';
import displayWalletErrorToast from '@shared/helpers/displayWalletErrorToast';
import useWeb3, { RequestSignProps } from '@shared/hooks/web3/useWeb3';

const useLoginWithGivenWallet = ({ onLogout }: { onLogout: () => void }) => {
  const { requestSign, isLoading, setIsLoading } = useWeb3();
  const { mutateAsync: loginWithWallet } = useAuthMutation();

  return useMutation({
    mutationFn: async ({
      signResults,
      signProps,
      selfRequestSign = true,
    }: {
      signResults?: RequestSignResult;
      signProps: RequestSignProps;
      selfRequestSign?: boolean;
    }) => {
      if (isLoading) {
        return;
      }

      const toastId = new Date().getTime();

      try {
        setIsLoading(true);

        toast.loading('Loading...', {
          toastId,
          style: { borderRadius: '8px', background: '#130e3a', color: '#fff' },
          closeOnClick: true,
        });

        if (!signResults && selfRequestSign) {
          signResults = await requestSign(signProps);
        }

        if (signResults && Object.keys(signResults).length) {
          const { signature, message, address, walletType, network, v2 } = signResults;
          const userInfo = await loginWithWallet({
            signature,
            message,
            address,
            walletType,
            network,
            v2,
          });

          return userInfo;
        }

        Cookies.remove(storageKeys.REFERRAL);
        Cookies.remove(storageKeys.LOGGED_INTO_MAGIC_BOOST);
      } catch (error) {
        const normalizedError: Error = normalizeError(error);

        if (normalizedError.message === namedWeb3Errors.SIGNATURE_VERIFICATION_HAS_FAILED) {
          throw normalizedError;
        }

        displayWalletErrorToast({ error });
        onLogout();
      } finally {
        toast.dismiss(toastId);
        setIsLoading(false);
      }
    },
  });
};

export default useLoginWithGivenWallet;
