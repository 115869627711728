import Link from 'next/link';

import React, { ComponentPropsWithoutRef, MouseEvent, useCallback } from 'react';

import classNames from 'classnames';
import { useDisconnect } from 'wagmi';

// import { useDisconnect } from 'wagmi';
import { Dropdown, DropdownItem } from '@uikit/components/Dropdown';
import { AngleRight } from '@uikit/icons/AngleRight';

import { useTagManager } from '@shared/common/providers/GoogleTagManagerProvider';
import truncate from '@shared/helpers/web3/truncate';
import useLoginWithGivenWallet from '@shared/hooks/useLoginWithGivenWallet';

import { useAuth } from '@launchpad/components/providers/AuthProvider/AuthProvider';

interface ConnectWalletButtonProps extends ComponentPropsWithoutRef<'button'> {
  showDisconnectPrefix?: boolean;
}

export const useAuthModal = () => {
  const { logout } = useAuth();
  const { mutateAsync: loginWithWallet, isPending: isLoading } = useLoginWithGivenWallet({
    onLogout: logout,
  });
  const { triggerAuthEvent } = useTagManager();

  const login = useCallback(async () => {
    const userInfo = await loginWithWallet({
      signProps: {
        walletType: 'WalletConnect2',
        signType: 'SIWE',
        message: 'Confirm authorization in Magic Launchpad Platform with your wallet',
        shouldVerifyChain: true,
      },
    });

    if (!userInfo) {
      return;
    }

    if (userInfo.isNewUser) {
      triggerAuthEvent(userInfo.user.id, {
        isNewUser: true,
      });
    }
  }, [triggerAuthEvent, loginWithWallet]);

  return {
    login,
    isLoading,
  };
};

const ConnectWalletButton = ({
  className,
  showDisconnectPrefix,
  ...props
}: ConnectWalletButtonProps) => {
  const { isAuth, launchpadUser, isLoading, logout } = useAuth();
  const { login: loginWithWallet, isLoading: isLoggingInWithWallet } = useAuthModal();
  const { disconnect } = useDisconnect();

  const handleDisconnect = () => {
    logout();
    disconnect();
  };

  const isConnectWalletButtonDisabled = isLoading || isLoggingInWithWallet;

  if (!launchpadUser?.attributes.address || !isAuth) {
    return (
      <button
        disabled={isConnectWalletButtonDisabled}
        onClick={loginWithWallet}
        data-qa="connect-wallet-button"
        className={classNames('ui-button ui-button-solid-primary min-w-32 px-4', className)}
        {...props}
      >
        {!isAuth ? 'Connect Wallet' : 'No EVM Wallet'}
      </button>
    );
  }

  return (
    <Dropdown
      trigger={({ triggerProps: { onClick, ...triggerProps } }) => {
        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
          if (onClick) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            onClick(event);
          }
        };

        return (
          <button
            disabled={isLoading}
            onClick={handleClick}
            data-qa="connected-wallet-button"
            {...triggerProps}
            className={classNames(
              'ui-button ui-button-solid-primary min-w-32 gap-2 px-4',
              className,
            )}
            {...props}
          >
            {showDisconnectPrefix && 'Disconnect '}
            {truncate(launchpadUser.attributes.address)}
            <AngleRight className={'icon-xs rotate-90'} />
          </button>
        );
      }}
    >
      <DropdownItem Component={Link} href="/portfolio" label="Dashboard" />
      <DropdownItem onClick={handleDisconnect} label="Disconnect" />
    </Dropdown>
  );
};

export default ConnectWalletButton;
